<template>
  <div class="orderQuery">
    <!-- 搜索框区域 -->
    <div class="search-box">
      <div class="flexbox flex-align-center flex-wrap">
        <div class="mr60 mt20">
          <span class="name">订单状态</span>
          <el-select v-model="form.orderState" clearable class="inp">
            <el-option v-for="item in stateOptions" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="mr60 mt20">
          <span class="name">流水号</span>
          <el-input v-model="form.orderNo" class="inp" clearable placeholder="输入流水号"></el-input>
        </div>
        <div class="mr60 mt20">
          <span class="name">商户名称</span>
          <el-input v-model="form.merchantName" class="inp" clearable placeholder="输入商户名称"></el-input>
        </div>
        <div class="mr60 mt20">
          <span class="name">收货人</span>
          <el-input v-model="form.acceptNameOrAcceptPhone" class="inp" clearable placeholder="收货人名称/手机号"></el-input>
        </div>
        <div class="mr60 mt20">
          <span class="name">骑手</span>
          <el-input v-model="form.riderNameOrRiderPhone" class="inp" clearable placeholder="骑手姓名/手机号"></el-input>
        </div>
        <div class="mr60 mt20">
          <span class="name">是否异常</span>
          <el-select v-model="form.exception" clearable class="inp">
            <el-option v-for="item in orderOptions" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="mr60 mt20">
          <span class="name">订单来源</span>
          <el-select v-model="form.orderSource" clearable class="inp">
            <el-option v-for="(item, index) in orderSourceOptions" :key="index" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="mr60 mt20">
          <span class="name">单号搜索</span>
          <el-input v-model="form.orderNumber" style="width: 200px;" class="inp" clearable placeholder="输入订单/配送单号/票号"></el-input>
        </div>
        <div class="mr60 mt20">
          <span class="name">收发地址</span>
          <el-input v-model="form.acceptOrMerchantAddress" class="inp" clearable placeholder="输入地址信息"></el-input>
        </div>
      </div>
      <div class="flexbox flex-align-center flex-wrap">
        <div class="mr60 mt26">
          <span class="name">订单日期</span>
          <span :class="mode == '1' ? 'mode mode-active' : 'mode'" @click="selectMode('1')">今日</span>
          <span :class="mode == '2' ? 'mode mode-active' : 'mode'" @click="selectMode('2')">昨日</span>
          <span :class="mode == '3' ? 'mode mode-active' : 'mode'" @click="selectMode('3')">近7日</span>
          <span :class="mode == '4' ? 'mode mode-active' : 'mode'" @click="selectMode('4')">近30日</span>
        </div>
        <el-date-picker v-model="time" class="time mt26" type="daterange" range-separator="至" value-format="yyyy-MM-dd"
          start-placeholder="选择开始日期" end-placeholder="选择结束日期" :clearable="false" :picker-options="dateRange" @change="timeChange">
        </el-date-picker>
        <el-button type="primary" class="btn search-btn" @click="search($event)">筛选</el-button>
        <el-button type="info" class="btn clear-btn" @click="clear">清空</el-button>
      </div>
    </div>
    <!-- 订单信息区域 -->
    <div class="order-info flexbox flex-align-center">
      <div class="order-info-item">
        <span>{{ orderStatistics.process || 0 }}</span>
        <span>进行中的订单数</span>
      </div>
      <div class="order-info-item">
        <span>{{ orderStatistics.today || 0 }}</span>
        <span>今日单数</span>
      </div>
      <div class="order-info-item">
        <span>{{ orderStatistics.month || 0 }}</span>
        <span>本月单数</span>
      </div>
      <div class="order-info-item">
        <span>{{ orderStatistics.total || 0 }}</span>
        <span>总单数</span>
      </div>
      <div class="order-info-total-num">共{{totalNum || 0}}条数据</div>
    </div>
    <!-- table表格区域 -->
    <div class="table-box">
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column label="单号">
          <template slot-scope="scope">
            <p class="ticketNumber" v-if="scope.row.transCode">票号：{{ scope.row.transCode }}</p>
            <p class="ticketNumber" v-if="scope.row.orderNo">流水号：#{{ scope.row.orderNo }}</p>
            <p>配送单号：{{ scope.row.skOrderId }}</p>
            <p class="mt14">商户订单号：{{ scope.row.shopOrderId }}</p>
          </template>
        </el-table-column>
        <el-table-column label="订单来源">
          <template slot-scope="scope">
            <span>{{ orderSourceOptions[scope.row.orderSource].label }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="" label="订单信息" width="240px">
          <template slot-scope="scope">
            <div class="mt14" v-if="scope.row.state == 1">状态：待接单</div>
            <div class="mt14" v-else-if="scope.row.state == 2">
              状态：已接单
            </div>
            <div class="mt14" v-else-if="scope.row.state == 3">
              状态：已到店
            </div>
            <div class="mt14" v-else-if="scope.row.state == 4">
              状态：配送中
            </div>
            <div class="mt14" v-else-if="scope.row.state == 5">
              状态：配送完成
            </div>
            <div class="mt14" v-else-if="scope.row.state == 6">
              状态：撤销订单
            </div>
            <div class="mt14">商家名称：{{ scope.row.merchantName || '-' }}</div>
            <div class="mt14">
              骑手：{{ scope.row.riderName }} — {{ scope.row.riderPhone }}
            </div>
            <div class="mt14">发单时间：{{ scope.row.createTime }}</div>
            <div class="mt14" v-if="scope.row.state == 5">
              送达时间：{{ scope.row.realArriveTime }}
            </div>
            <div class="mt14" v-else>
              预计送达：{{ scope.row.preArriveTime }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="" label="收发地址" min-width="310px">
          <template slot-scope="scope">
            <div class="flexbox flex-align-center">
              <img src="/img/delivery/take.png" class="address-icon" alt="" />
              <div>
                <span>{{ scope.row.merchantName }}-</span>
                <span>{{ scope.row.merchantTel }}-</span>
                <span>{{ scope.row.merchantAddress }}</span>
              </div>
            </div>
            <div class="flexbox flex-align-center mt46">
              <img src="/img/delivery/deliver.png" class="address-icon" alt="" />
              <div>
                <span>{{ scope.row.acceptName }}-</span>
                <span>{{ scope.row.acceptTel }}-</span>
                <span>{{ scope.row.acceptAddress }}</span>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="orderDistance" align="center" label="距离">
          <template slot-scope="scope">
            <span>{{ scope.row.orderDistance }}km</span>
          </template>
        </el-table-column>
        <el-table-column prop="exceptionMsg" align="center" label="异常信息">
          <template slot-scope="scope">
            <span class="info" v-if="scope.row.exceptionMsg">{{
              scope.row.exceptionMsg
            }}</span>
            <span class="info" v-else>无</span>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="profitMoney" align="center" label="骑手本单收入">
          <template slot-scope="scope">
            <span v-if="
                (scope.row.state == 5 || scope.row.state == 6) &&
                scope.row.shareProfit != null
              ">￥{{ scope.row.shareProfit }}</span>
            <span v-else-if="
                (scope.row.state == 5 || scope.row.state == 6) &&
                scope.row.shareProfit == null
              ">-</span>
            <span v-else-if="scope.row.profitMoney == null">-</span>
            <span v-else>￥{{ scope.row.profitMoney }}</span>
          </template>
        </el-table-column> -->
        <el-table-column prop="state" align="center" label="订单状态">
          <template slot-scope="scope">
            <span v-if="scope.row.state == 1">待接单</span>
            <span v-else-if="scope.row.state == 2">已接单</span>
            <span v-else-if="scope.row.state == 3">已到店</span>
            <span v-else-if="scope.row.state == 4">配送中</span>
            <span v-else-if="scope.row.state == 5">配送完成</span>
            <span v-else-if="scope.row.state == 6">撤销订单</span>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="state" align="center" label="订单图片">
          <template slot-scope="scope">
            <el-image style="width: 40px; height: 40px"
                :src="scope.row.takeImgUrl"
                :preview-src-list="[scope.row.takeImgUrl]">
              </el-image>
            <el-image style="width: 40px; height: 40px"
                :src="scope.row.arriveImgUrl"
                :preview-src-list="[scope.row.arriveImgUrl]">
              </el-image>
          </template>
        </el-table-column> -->
        <!-- 订单调度 订单查询 都不要订单详情 -->
        <el-table-column prop="name" align="center" label="操作">
          <template slot-scope="scope">
            <el-button type="text" v-if="+scope.row.orderSource !== 2" @click="
                openOrderDetailDialog(scope.row.skOrderId, scope.row.state)
              ">订单详情</el-button>
            <el-button type="text" v-if="scope.row.state==4||scope.row.state==5" @click="
                openRecords(scope.row)
              ">配送记录</el-button>

          </template>
        </el-table-column>
        -->
      </el-table>
    </div>
    <el-pagination class="mt10" background :page-count="totalPage" :current-page.sync="currentPage" @current-change="pageChange">
    </el-pagination>
    <!-- 订单详情弹窗 -->
    <orderDetail ref="orderDetail">
      
    </orderDetail>
    <!-- 配送详情 -->
    <deliveryRecordDialog ref="deliverDialog"></deliveryRecordDialog>
    <!-- 接力骑手配送说明
    <relayExplain :relayExplainDialog="relayExplainDialog" v-on:closeExplain="closeExplain"></relayExplain> -->
  </div>
</template>

<script>
import orderDetail from '@/components/order/orderDetail.vue';
import { $sg_queryPageList, $sg_getOrderStatistics } from '@/api/delivery';
import deliveryRecordDialog from '@/components/order/deliveryRecordDiaLog.vue';

export default {
  data() {
    return {
      form: {
        exception: '',
        orderState: '',
        riderNameOrRiderPhone: '',
        orderSource: '',
        orderNo: '',
        merchantName: '',
        acceptNameOrAcceptPhone: '',
        orderNumber: '',
        acceptOrMerchantAddress: '',
      },
      time: '',
      mode: '',
      stateOptions: [
        {
          value: '',
          label: '全部',
        },
        {
          value: '1',
          label: '待接单',
        },
        {
          value: '2',
          label: '已接单',
        },
        {
          value: '3',
          label: '已到店',
        },
        {
          value: '4',
          label: '配送中',
        },
        {
          value: '5',
          label: '配送完成',
        },
        {
          value: '6',
          label: '撤销订单',
        },
      ],
      orderOptions: [
        {
          value: '',
          label: '全部',
        },
        {
          value: '1',
          label: '正常订单',
        },
        {
          value: '2',
          label: '异常订单',
        },
      ],
      orderSourceOptions: [
        {
          value: '',
          label: '全部',
        },
        {
          value: 1,
          label: '松鼠',
        },
        {
          value: 2,
          label: '三方',
        },
      ],
      orderStatistics: {},
      tableData: [],
      totalPage: 0,
      currentPage: 1,
      recordsDialog: false, // 配送详情
      // relayExplainDialog: true, // 接力骑手配送说明
      recordsId: '', // 配送id
      orderData: {}, // 再次配送数据
      // 日期选择器可选择范围
      dateRange: {
        disabledDate(time) {
          // 限制最早只能从5月份开始查询, 最大为当天
          return (
            new Date(time).getTime() < new Date('2021-04-30').getTime()
            || new Date(time).getTime() > new Date().getTime()
          );
        },
      },
      totalNum: 0,
    };
  },
  components: {
    orderDetail,
    deliveryRecordDialog,
  },
  created() {
    this.selectMode('4');
    this.search();
    this.getOrderStatistics();
  },
  methods: {
    // 获取订单列表
    search(e) {
      this.btnBlur(e);
      this.currentPage = 1;
      this.getList();
    },
    getList() {
      const params = {
        pageCurrent: this.currentPage,
        pageSize: 15,
        hasAllState: 1, //
        ...this.form,
      };
      params.orderStartDate = this.time && this.time.length > 1 ? this.time[0] : '';
      params.orderEndDate = this.time && this.time.length > 1 ? this.time[1] : '';
      $sg_queryPageList(params).then((res) => {
        this.tableData = res.records;
        this.totalPage = res.pages;
        this.totalNum = res.total;
      });
    },
    btnBlur(e) {
      if (e && e.type === 'click') {
        let target = e.target;
        if (target.nodeName === 'SPAN') {
          target = e.target.parentNode;
        }
        target.blur();
      }
    },
    // 获取订单数据
    getOrderStatistics() {
      $sg_getOrderStatistics().then((res) => {
        this.orderStatistics = res;
      });
    },
    // 选择订单日期
    selectMode(mode) {
      const date = new Date().getTime() / 1000;
      // 获取今天日期
      const today = this.$formatDate(date, 'yyyy-MM-dd');
      // 获取昨天日期
      const yesterday = this.$formatDate(date - 1 * 24 * 60 * 60, 'yyyy-MM-dd');
      // 获取7天前日期
      const beforeSevenDay = this.$formatDate(
        date - 7 * 24 * 60 * 60,
        'yyyy-MM-dd',
      );
      // 获取30天前日期
      const beforeThirtyDay = this.$formatDate(
        date - 30 * 24 * 60 * 60,
        'yyyy-MM-dd',
      );
      switch (mode) {
        // 今天
        case '1':
          this.time = [today, today];
          break;
        // 昨天
        case '2':
          this.time = [yesterday, yesterday];
          break;
        // 近7日
        case '3':
          this.time = [beforeSevenDay, today];
          break;
        // 近30日
        case '4':
          this.time = [beforeThirtyDay, today];
          break;
        default:
          break;
      }
      this.mode = mode;
    },
    // 日期选择器改变事件
    timeChange(val) {
      if (!val) {
        this.selectMode('1');
      } else {
        this.mode = '';
      }
    },
    // 清空
    clear() {
      Object.keys(this.form).forEach((item) => {
        this.form[item] = '';
      });
      this.selectMode('1');
      this.currentPage = 1;
      this.getList();
    },

    // 打开配送记录
    openRecords(row) {
      console.log(row);
      this.$refs.deliverDialog.openDialog(row.skOrderId);
    },
    // 打开订单详情弹窗
    openOrderDetailDialog(skOrderId, state) {
      this.$refs.orderDetail.open(skOrderId, state);
    },
    // 打开骑手配送说明
    closeExplain(close) {
      this.relayExplainDialog = close;
    },
    // 翻页
    pageChange() {
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/var.scss";
.orderQuery {
  width: 100%;
  background-color: #fff;
  color: #666;
  font-size: 14px;
  padding: 20px 15px 34px 25px;
  box-sizing: border-box;
  .name {
    margin-right: 10px;
  }
  .inp {
    width: 180px;
  }
  .mode {
    display: inline-block;
    padding: 6px 26px;
    color: #999;
    border: 1px solid #999;
    border-radius: 5px;
    margin-right: 14px;
    user-select: none;
    cursor: pointer;
  }
  & > .mode:last-child {
    margin-right: 46px;
  }
  .mode-active {
    color: $--theme-color;
    border: 1px solid $--theme-color;
  }
  .time {
    margin-right: 34px;
  }
  .btn {
    width: 80px;
    height: 32px;
    margin-top: 26px;
  }
  .search-btn {
    margin-right: 20px;
  }
  .clear-btn {
    background-color: #666;
    margin-left: 0px;
  }
  .order-info {
    width: 100%;
    height: 104px;
    margin-top: 36px;
    background-color: #f5f5f5;
    position: relative;
    .order-info-item {
      margin-left: 186px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      & > span:first-child {
        font-size: 32px;
        color: $--theme-color;
        font-weight: bold;
      }
      & > span:last-child {
        font-size: 18px;
        color: #666666;
        margin-top: 2px;
      }
    }
    & > .order-info-item:first-child {
      margin-left: 110px;
    }
    .order-info-total-num {
      font-size: 14px;
      position: absolute;
      right: 10px;
      bottom: 10px;
      font-weight: bold;
    }
  }
  .table-box {
    width: 100%;
    margin-top: 24px;
    .el-table {
      min-height: 580px;
      & > th {
        background-color: $--theme-color;
        color: #fff;
      }
    }
    .ticketNumber {
      color: #ef3f46;
      font-weight: bold;
    }
    .info {
      color: #fa8828;
    }
    .address-icon {
      width: 23.85px;
      height: 23.85px;
      margin-right: 8px;
    }
  }
  .mr60 {
    margin-right: 60px;
  }
  .mr36 {
    margin-right: 36px;
  }
  .mt26 {
    margin-top: 26px;
  }
  .mt14 {
    margin-top: 14px;
  }
  .mt46 {
    margin-top: 46px;
  }

  .position-button {
    line-height: 25px;
    background: #ed3e45;
    color: #fff;
    border-radius: 8px;
    width: 80px;
    border: 0px;
  }
}
</style>
